<template>
  <div>
    <el-select
      @keyup.enter.native="next()"
      v-model="fieldValue"
      :placeholder="data.fields.placeholder || '--'"
      v-if="data.fields.type === 'location' && this.type === 'country'"
      :key="data.fields.type"
      :popper-append-to-body="false"
    >
      <el-option
        v-for="option in data.fields.options"
        :key="option"
        :value="option"
      >
      </el-option>
    </el-select>

    <el-select
      @keyup.enter.native="next()"
      v-model="fieldValue"
      :placeholder="data.fields.placeholder || '--'"
      v-if="data.fields.type === 'location' && this.type === 'state'"
      :key="data.fields.type"
      :popper-append-to-body="false"
    >
      <el-option
        v-for="option in statesList"
        :key="option.name"
        :value="option.name"
      >
      </el-option>
    </el-select>

    <el-select
      @keyup.enter.native="next()"
      v-model="fieldValue"
      :placeholder="data.fields.placeholder || '--'"
      v-if="data.fields.type === 'location' && this.type === 'city'"
      :key="data.fields.type"
      :popper-append-to-body="false"
    >
      <el-option
        v-for="option in citiesList"
        :key="option.name"
        :value="option.name"
      >
      </el-option>
    </el-select>

    <el-select
      @keyup.enter.native="next()"
      v-model="fieldValue"
      :placeholder="data.fields.placeholder || '--'"
      v-if="data.fields.type === 'location' && this.type === 'town'"
      :key="data.fields.type"
      :popper-append-to-body="false"
    >
      <el-option
        v-for="option in data.fields.options"
        :key="option"
        :value="option"
      >
      </el-option>
    </el-select>

    <div class="" v-if="type === 'gps'">
      <div class="geo-button-wrapper">
        <el-button
          type="danger"
          @click="getLocation"
          v-if="!gettingLocation && !geoActive"
        >
          Activar geolocalización
        </el-button>
        <el-button
          type="danger"
          disabled
          v-if="gettingLocation && !geoActive"
        >
          Procesando...
        </el-button>
      </div>
      <div class="geo-message-wrapper">
        <transition-group name="fade">
          <p v-if="geoError" key="error">{{geoError}}</p>
          <h2 v-if="geoActive" key="success">¡Listo!</h2>
        </transition-group>
      </div>
    </div>

    <div v-if="this.data.fields.altOption" class="alt-option">
      <div
        @click="redirectToSurvey()"
      >{{this.data.fields.altOption}}</div>
    </div>
  </div>
</template>

<script>
import Screen from 'Components/questions/Screen'
import Vue from 'vue'

export default {
  name: 'LocationSelector',

  props: [ 'data' ],

  components: {
  },

  data () {
    return {
      clearTimeout: null,
      fieldValue: '',
      geolocation: {},
      gettingLocation: false,
      geoGPS: {},
      geoActive: false,
      geoError: '',
      countriesList: {
        "Perú": 'Peru',
        "República Dominicana": 'Dominican Republic',
        "Panamá": 'Panama',
        "México": 'Mexico',
        "Canadá": 'Canada',
        "Estados Unidos": 'United States',
        "Brasil": "Brazil",
        "España": "Spain"
      }
    }
  },

  computed: {
    type () {
      var locationSettings = this.data.fields.locationSettings
      if (locationSettings) {
        if (locationSettings.includes('GPS')) {
          return 'gps'
        }
        if (locationSettings.includes('Country')) {
          return 'country'
        }
        if (locationSettings.includes('State')) {
          return 'state'
        }
        if (locationSettings.includes('City')) {
          return 'city'
        }
        if (locationSettings.includes('Town')) {
          return 'town'
        }
      }
    },
    statesList () {
      return []
    },
    citiesList () {
      return []
    }
  },

  mounted () {
    if (this.data.fields.locationSettings && this.data.fields.locationSettings.includes('Geolocation')) {
      Vue.axios.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.VUE_APP_GEOCODING_API}`)
        .then(data => {
          this.geolocation = {
            type: "Point",
            coordinates: [ data.data.location.lng, data.data.location.lat ],
          }
          this.$store.commit('STORE_GEOLOCATION', this.geolocation)
          this.$store.commit('STORE_GEOLOCATION_ACCURACY', data.data.accuracy)
        })
        .catch(err => {
          console.log('error: ', err)
        })
    }
  },

  methods: {
    redirectToSurvey () {
      this.$store.dispatch('LOAD_NEW_SURVEY', { surveyId: this.data.fields.altOptionRedirect })
        .then(() => {
          // TODO: this does not work exactly as planned as the params are already in memory
          //this.$router.push(`/s/${newVal}/0?utm_campaign=live&utm_medium=referral&utm_source=${this.$route.params.sid}`)
          this.$router.push({
            name: 'survey',
            params: {
              sid: this.data.fields.altOptionRedirect,
              qid: 0
            }
          })
        })
    },
    getLocation() {
      this.gettingLocation = true
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition( (position) => {
          this.geoActive = true
          this.geoError = ''
          this.geolocation = {
            type: "Point",
            coordinates: [position.coords.longitude, position.coords.latitude] 
          };
          this.$store.commit('STORE_GEOLOCATION', this.geolocation)
          this.$store.commit('STORE_GEOLOCATION_ACCURACY', 0)
        }, () => {
          this.handleLocationError(`Debe aceptar compartir tu ubicación. En la configuración del sitio en su navegador, habilite la ubicación para ${process.env.VUE_APP_BASE_URL} y refresque la página para poder continuar.`);
        });
      } else {
        this.handleLocationError('Su navegador no parece tener soporte de geolocalización. Intente con otro dispositivo.');
      }
    },    
    handleLocationError(message) {
      this.geoError = message
    },
    distance (lat1, lon1, lat2, lon2, unit) {
      if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
      }
      else {
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = lon1-lon2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="K") { dist = dist * 1.609344 }
        if (unit=="N") { dist = dist * 0.8684 }
        return dist;
      }
    },
    next () {
      this.emitNext()
    },
    emitNext () {
      this.$emit('next')
    },
    emitSelect (value) {
      this.$emit('select', this.fieldValue)
    },
    storeCity () {
      this.$store.commit('STORE_LOCATION_ITEM', { field: 'city', value: '' })
    },
    storeTown () {
      this.$store.commit('STORE_LOCATION_ITEM', { field: 'town', value: this.fieldValue })
    }
  },

  watch: {
    fieldValue: function (newValue) {
      this.emitSelect()
    },
    geoActive: function (newValue) {
      setTimeout(() => {
        this.fieldValue = this.newValue
        this.emitSelect(this.newValue)
      }, 1000)

    }
  }
}
</script>

<style lang="scss">
.alt-option {
  margin-top: 2em;
  font-size: 16px;
  a {
    color: rgba($text-main, 0.6);
    text-decoration: none;
    border-bottom: 1px solid $gray-light;
  }
}
.geo-button-wrapper {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;  
  border: 1px solid transparent;
}
.geo-message-wrapper {
  min-height: 140px;
  margin-top: 1em;
  border: 1px solid transparent;
  width: 100%;
}
.el-select {
  width: 100%;
}

</style>
